import { createSlice } from "@reduxjs/toolkit";
import utils_img from "../../assets/images/utils_2.webp";

import TTYT_icon from "../../assets/icons/TTYT_icon.png";
import THLC_icon from "../../assets/icons/THLC_icon.png";
import CC_icon from "../../assets/icons/CC_icon.png";
import TTTM_icon from "../../assets/icons/TTTM_icon.png";
import VTD_icon from "../../assets/icons/VTD_icon.png";
import BRO_icon from "../../assets/icons/BRO_icon.png";
import CVOM_icon from "../../assets/icons/CVOM_icon.png";
import RCIA_icon from "../../assets/icons/RCIA_icon.png";
import VCAQ_icon from "../../assets/icons/VCAQ_icon.png";
import QTNN_icon from "../../assets/icons/QTNN_icon.png";
import DCB_icon from "../../assets/icons/DCB_icon.png";
import DDX_icon from "../../assets/icons/DDX_icon.png";
import KVC_icon from "../../assets/icons/KVC_icon.png";
import KXH_icon from "../../assets/icons/KXH_icon.png";
import VDCN_icon from "../../assets/icons/VDCN_icon.png";
import QT_icon from "../../assets/icons/QT_icon.png";
import gym_icon from "../../assets/icons/gym_icon.png";
import VAT_icon from "../../assets/icons/VAT_icon.png";
import VYDS_icon from "../../assets/icons/VYDS_icon.png";
import VHD_icon from "../../assets/icons/VHD_icon.png";
import TVC_icon from "../../assets/icons/TVC_icon.png";
import spa_icon from "../../assets/icons/spa_icon.png";
import massage_icon from "../../assets/icons/massage_icon.png";
import restaurant_icon from "../../assets/icons/restaurant_icon.png";
import SCC_icon from "../../assets/icons/SCC_icon.png";
import VNNTN_icon from "../../assets/icons/VNNTN_icon.png";
import SBR_icon from "../../assets/icons/SBR_icon.png";
import BBQ_icon from "../../assets/icons/BBQ_icon.png";
import QTTT_icon from "../../assets/icons/QTTT_icon.png";
import road_icon from "../../assets/icons/road_icon.png";
import rvd_icon from "../../assets/icons/rvd_icon.png";
import KLN_icon from "../../assets/icons/KLN_icon.png";
import RCP_icon from "../../assets/icons/RCP_icon.png";
import VKN_icon from "../../assets/icons/VKN_icon.png";
import CTBR_icon from "../../assets/icons/CTBR_icon.png";
import VRC_icon from "../../assets/icons/VRC_icon.png";
import tennis_icon from "../../assets/icons/tennis_icon.png";

const initialState = {
  utilsActiveNumber: 0,
  utilsActiveSubmenuNumber: 0,
  utilsActiveChildmenuNumber: 0,
  utilsActiveNumberList: [
    {
      id: 1,
      text: "Tổng Quan",
      img: utils_img,
      dot: [
        // BẾN DU THUYỀN
        {
          id: 1,
          flaghotspot: true,
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/vcaq.jpg",
          frame: "scene_CVVS03",
          text: "Bến Du Thuyền",
          top: "23%",
          left: "64.5%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 2,
          // flaghotspot: true,
          visible: false,
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/vcaq.jpg",
          frame: "scene_bdt_cf",
          text: "Cà Phê Ngoài Trời",
          // top: "43%",
          // left: "29%",
          // topMobile: "38%",
          // leftMobile: "29%",
        },

        // RW
        // Công Viên Ven Sông
        // Công Viên Six Senses
        
        {
          id: 4,
          text: "Nhà hàng thực dưỡng ven sông",
          icon_img: restaurant_icon,
          // frame: "scene_cvvs02",
          top: "32%",
          left: "68%",
          topText: "58%",
          leftText: "41%",
          topMobile: "14%",
          leftMobile: "28%",
        },
        {
          id: 6,
          text: "Đường chạy bộ 5 giác quan",
          icon_img: DCB_icon,
          frame: "scene_cvvs01",
          // iconRight: true,
          top: "29%",
          left: "62%",
          topText: "52%",
          leftText: "28.2%",
          topMobile: "14%",
          leftMobile: "28%",
        },
        {
          id: 5,
          text: "Outdoor Gym Center",
          icon_img: gym_icon,
          frame: "scene_cvvs02_d",
          // iconRight: true,
          top: "28%",
          left: "58%",
          topText: "58%",
          leftText: "31.5%",
          topMobile: "14%",
          leftMobile: "28%",
        },
        // QUẢNG TRƯỜNG ALL BLUE
        {
          id: 7,
          text: "Quảng Trường Xanh",
          icon_img: CVOM_icon,
          frame: "scene_qtx_themvongcanh",
          top: "37%",
          left: "43%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 7001,
          visible: false,
          text: "Thềm vọng cảnh",
          frame: "scene_qtx_themvongcanh",
          icon_img: VTD_icon,
          top: "33.5%",
          left: "55%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 7002,
          visible: false,
          text: "Rạp chiếu phim ngoài trời",
          frame: "scene_qtx_cinema",
          icon_img: VTD_icon,
          top: "33.5%",
          left: "55%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 7003,
          visible: false,
          text: "Sân Cỏ Đa Năng",
          frame: "scene_qtx_sancodanang",
          icon_img: VTD_icon,
          top: "33.5%",
          left: "55%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 8,
          text: "Quảng Trường Nhiệt Đới",
          icon_img: RCIA_icon,
          frame: "scene_qtnd_vdn",
          top: "40%",
          left: "37%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 8001,
          visible: false,
          text: "Vườn dược liệu",
          icon_img: VTD_icon,
          frame: "scene_cvtt01_d",
          top: "33.5%",
          left: "55%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 8002,
          visible: false,
          text: "Đường chạy ion âm",
          icon_img: RCIA_icon,
          frame: "scene_cvtt01",
          top: "36%",
          left: "53%",
          topText: "36%",
          leftText: "53%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 8003,
          visible: false,
          text: "Vườn dã ngoại",
          icon_img: RCIA_icon,
          frame: "scene_qtnd_vdn",
          top: "36%",
          left: "53%",
          topText: "36%",
          leftText: "53%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 8004,
          visible: false,
          text: "Công viên ôm cây",
          icon_img: CVOM_icon,
          frame: "scene_cvtt03_d",
          // iconRight: true,
          top: "32%",
          left: "52%",
          topText: "32%",
          leftText: "45.5%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 8005,
          visible: false,
          text: "Vườn hoa đạo",
          icon_img: VHD_icon,
          frame: "scene_cvtt02",
          top: "31%",
          left: "57%",
          topText: "31%",
          leftText: "56%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 8005,
          visible: false,
          text: "Vườn y đạo",
          icon_img: VHD_icon,
          frame: "scene_cvtt02_d",
          top: "28%",
          left: "54%",
          topText: "28%",
          leftText: "54%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 8006,
          visible: false,
          text: "Bếp rừng organic",
          icon_img: BRO_icon,
          frame: "scene_cvtt03",
          // iconRight: true,
          top: "29%",
          left: "51%",
          topText: "29%",
          leftText: "46.2%",
          topMobile: "38%",
          leftMobile: "29%",
        },

        {
          id: 9001,
          visible: false,
          text: "Vườn tuổi thơ",
          icon_img: VTD_icon,
          frame: "scene_qttt_vuontuoitho",
          top: "33.5%",
          left: "55%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 9002,
          visible: false,
          text: "Sân trượt Patin",
          icon_img: RCIA_icon,
          frame: "scene_qttt_santruotpatin",
          top: "36%",
          left: "53%",
          topText: "36%",
          leftText: "53%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 9003,
          visible: false,
          text: "Vườn BBQ",
          icon_img: RCIA_icon,
          frame: "scene_qttt_vuonbbq",
          top: "36%",
          left: "53%",
          topText: "36%",
          leftText: "53%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 10,
          text: "Quảng Trường Trung Tâm",
          icon_img: QT_icon,
          frame: "scene_qttt_daiphunnuoc",
          top: "42%",
          left: "43%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 10001,
          visible: false,
          text: "Đài phun nước",
          icon_img: VTD_icon,
          frame: "scene_qttt_daiphunnuoc",
          top: "33.5%",
          left: "55%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 9,
          text: "Quảng Trường Trẻ Thơ",
          icon_img: QTTT_icon,
          frame: "scene_qttt_vuontuoitho",
          top: "38%",
          left: "50%",
          topText: "33.5%",
          leftText: "55%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        // Onsen
        // Osen Clubhouse
        {
          id: 11,
          visible: false,
          text: "Vườn Nhật",
          icon_img: KXH_icon,
          frame: "scene_osch_ngoaithat",
          top: "28%",
          left: "70%",
          // topText: "30%",
          // leftText: "68%",
          // topMobile: "38%",
          // leftMobile: "29%",
        },
        {
          id: 12,
          // visible: false,
          text: "hồ khoáng Onsen",
          icon_img: KXH_icon,
          frame: "scene_osch_onsen",
          top: "41%",
          left: "19%",
          topText: "28%",
          leftText: "63%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 13,
          visible: false,
          text: "Vườn Thưởng Trà",
          icon_img: massage_icon,
          frame: "scene_osch_massage",
          top: "34%",
          left: "67%",
          topText: "33%",
          leftText: "67%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 14,
          visible: false,
          text: "Sauna",
          icon_img: KXH_icon,
          frame: "scene_osch_sauna",
          top: "31%",
          left: "69%",
          topText: "30%",
          leftText: "68%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 15,
          visible: false,
          text: "Spa & Massage",
          icon_img: spa_icon,
          frame: "scene_osch_spa",
          // iconRight: true,
          top: "31%",
          left: "65%",
          topText: "31%",
          leftText: "62.4%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 16,
          visible: false,
          text: "Nhà Hàng",
          icon_img: restaurant_icon,
          frame: "scene_osch_restaurant",
          // iconRight: true,
          top: "35%",
          left: "64%",
          topText: "55%",
          leftText: "75%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        // SPORT CLUBHOUSE
        {
          id: 17,
          text: "sân chơi thể thao",
          visible: false,
          frame: "scene_sc_sportfield",
          icon_img: SBR_icon,
          top: "20%",
          left: "38%",
          topText: "35%",
          leftText: "36%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 18,
          flaghotspot: true,
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
          text: "Sport Clubhouse",
          frame: "scene_sc_pool",
          top: "64%",
          left: "66%",
          topMobile: "38%",
          leftMobile: "29%",
        },

        // Khác
        {
          id: 23,
          visible: false,
          text: "Quảng trường nhạc nước",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/qtnn.jpg",
          // iconRight: true,
          icon_img: QTNN_icon,
          top: "38%",
          left: "50%",
          topText: "38%",
          leftText: "43.8%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 24,
          text: "Khu vui chơi trẻ em",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/kvc.jpg",
          icon_img: KVC_icon,
          top: "24%",
          left: "38%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 25,
          text: "quảng trường ven sông",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/qtvs.jpg",
          icon_img: QT_icon,
          top: "18%",
          left: "37%",
          topText: "76%",
          leftText: "75%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 26,
          text: "thềm vọng cảnh",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/tvc.jpg",
          icon_img: TVC_icon,
          top: "66%",
          left: "78%",
          topText: "72%",
          leftText: "62%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 27,
          text: "vườn thảo dược",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/vtd.jpg",
          icon_img: VTD_icon,
          top: "29%",
          left: "37%",
          topText: "29%",
          leftText: "37%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 28,
          visible: false,
          text: "vườn cây ăn quả",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/vcaq.jpg",
          icon_img: VCAQ_icon,
          top: "35%",
          left: "36%",
          topText: "35%",
          leftText: "36%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 29,
          text: "vườn thiền",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/vt.jpg",
          icon_img: VYDS_icon,
          top: "26%",
          left: "35%",
          topText: "44%",
          leftText: "67%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 30,
          text: "vườn ngập nước tự nhiên",
          icon_img: VNNTN_icon,
          // iconRight: true,
          top: "65%",
          left: "90%",
          // left: "71%",
          topText: "19%",
          leftText: "57.5%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 31,
          text: "vườn ngập nước tự nhiên",
          icon_img: VNNTN_icon,
          top: "30%",
          left: "25%",
          topText: "19%",
          leftText: "57.5%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 32,
          text: "sân câu cá",
          icon_img: SCC_icon,
          // iconRight: true,
          top: "25%",
          left: "54%",
          topText: "19%",
          leftText: "57.5%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 3,
          text: "Đường đạp xe xuyên rừng",
          icon_img: DDX_icon,
          frame: "scene_cvvs01_d",
          // iconRight: true,
          top: "23%",
          left: "46%",
          topText: "55%",
          leftText: "29.6%",
          topMobile: "14%",
          leftMobile: "28%",
        },
        {
          id: 33,
          visible: false,
          text: "vườn cây ăn quả",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/vcaq.jpg",
          icon_img: VCAQ_icon,
          top: "55%",
          left: "60%",
          topText: "35%",
          leftText: "36%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        // {
        //   id: 34,
        //   text: "sân bóng đá, sân tennis",
        //   icon_img: SBR_icon,
        //   top: "20%",
        //   left: "35%",
        //   topText: "35%",
        //   leftText: "36%",
        //   topMobile: "38%",
        //   leftMobile: "29%",
        // },
        {
          id: 35,
          visible: false,
          text: "Khu vui chơi trẻ em",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/kvc.jpg",
          icon_img: KVC_icon,
          top: "18%",
          left: "45%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 36,
          text: "Vườn BBQ",
          icon_img: BBQ_icon,
          top: "27%",
          left: "27%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        // Khác đặc biệt
        {
          id: 37,
          text: "trung tâm y tế",
          background_color:
            "linear-gradient(to bottom, #FCC672 0%, #DAA046 50%)",
          icon_img: TTYT_icon,
          top: "80%",
          left: "80%",
          topText: "15%",
          leftText: "40%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 38,
          text: "trường học liên cấp",
          // iconRight: true,
          background_color:
            "linear-gradient(to bottom, #FCC672 0%, #DAA046 50%)",
          icon_img: THLC_icon,
          top: "81%",
          left: "68%",
          topText: "12%",
          leftText: "39.8%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 39,
          text: "cổng chào dự án",
          background_color:
            "linear-gradient(to bottom, #FCC672 0%, #DAA046 50%)",
          icon_img: CC_icon,
          top: "80%",
          left: "27%",
          topText: "14%",
          leftText: "50%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 40,
          text: "trung tâm thương mại",
          background_color:
            "linear-gradient(to right, #FCC672 50%, #DAA046 100%)",
          icon_img: TTTM_icon,
          top: "59%",
          left: "13%",
          topText: "19%",
          leftText: "57.5%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        // Flaghotspot đặc biệt
        {
          id: 41,
          flaghotspot: true,
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/cvtt.jpg",
          // text: "Công Viên Trung Tâm",
          text: "Quảng Trường All Blue",
          top: "33%",
          left: "42%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 43,
          flaghotspot: true,
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/vcaq.jpg",
          text: "Công Viên Riverwalk",
          top: "20%",
          left: "45%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 44,
          flaghotspot: true,
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/cvvs.jpg",
          // text: "Công Viên ven sông",
          text: "Công Viên Six Senses",
          top: "12%",
          left: "38%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 45,
          flaghotspot: true,
          frame: "scene_rsch01",
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/rc.jpg",
          text: "Resort Clubhouse",
          top: "17%",
          left: "25%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 46,
          flaghotspot: true,
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
          text: "Onsen Clubhouse",
          top: "34%",
          left: "18%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 47,
          flaghotspot: true,
          // frame: "scene_rsch01",
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
          text: "Kempinski Saigon River",
          top: "34%",
          left: "80%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        // {
        //   id: 48,
        //   visible: false,
        //   flaghotspot: true,
        //   // frame: "scene_rsch01",
        //   // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
        //   text: "Kempinski Clubhouse",
        //   top: "28%",
        //   left: "56%",
        //   topMobile: "38%",
        //   leftMobile: "29%",
        // },
        {
          id: 49,
          text: "Rừng Vận Động",
          frame: "scene_pf_kln",
          icon_img: rvd_icon,
          top: "53.5%",
          left: "45%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 49001,
          visible: false,
          text: "Khu Leo Núi",
          frame: "scene_pf_kln",
          icon_img: KLN_icon,
          top: "56%",
          left: "49.5%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 49002,
          visible: false,
          text: "Zipline",
          frame: "scene_pf_zipline",
          icon_img: BBQ_icon,
          top: "56%",
          left: "49.5%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 49003,
          visible: false,
          text: "Sân Chơi Cát",
          frame: "scene_pf_rvd",
          icon_img: BBQ_icon,
          top: "56%",
          left: "49.5%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },

        {
          id: 55,
          text: "Outdoor Gym",
          frame: "scene_pf_og",
          icon_img: gym_icon,
          top: "61%",
          left: "60%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 56,
          text: "Vườn Kết Nối",
          frame: "scene_pf_vkn",
          icon_img: VKN_icon,
          top: "58%",
          left: "57%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 54,
          text: "Rạp Chiếu Phim Ngoài Trời",
          frame: "scene_pf_cinema",
          icon_img: RCP_icon,
          top: "57%",
          left: "54%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 53,
          text: "Vườn BBQ",
          frame: "scene_pf_bbq",
          icon_img: BBQ_icon,
          top: "56%",
          left: "49%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },

        {
          id: 58,
          text: "Vườn Đọc Sách",
          frame: "scene_hf_vds",
          icon_img: THLC_icon,
          top: "44%",
          left: "25%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        
        {
          id: 60,
          text: "Tắm Rừng",
          frame: "scene_hf_tr",
          icon_img: RCIA_icon,
          top: "49%",
          left: "33%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 61,
          text: "Rừng Năng Lượng",
          frame: "scene_hf_rnl",
          icon_img: VNNTN_icon,
          top: "49%",
          left: "29%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 62,
          text: "Vườn Trái Cây",
          frame: "scene_ff_vtc",
          icon_img: VCAQ_icon,
          top: "68%",
          left: "49%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 65,
          text: "Vườn BBQ",
          frame: "scene_ff_vbbq",
          icon_img: BBQ_icon,
          top: "66%",
          left: "45%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 62,
          text: "Vườn Trái Cây",
          frame: "scene_ff_vtc",
          icon_img: VCAQ_icon,
          top: "66%",
          left: "41%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 63,
          text: "Vườn Rau Củ",
          frame: "scene_ff_vrc",
          icon_img: VRC_icon,
          top: "64%",
          left: "49%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 64,
          text: "Vườn Thảo Mộc",
          frame: "scene_ff_vtm",
          icon_img: VTD_icon,
          top: "63%",
          left: "43%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 57,
          text: "Đường Dạo Shinrin Yoku",
          frame: "scene_hf_ddsy",
          icon_img: road_icon,
          top: "47%",
          left: "24%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 57,
          text: "Đường Dạo Shinrin Yoku",
          frame: "scene_hf_ddsy",
          icon_img: road_icon,
          top: "52%",
          left: "37%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 57,
          text: "Đường Dạo Shinrin Yoku",
          frame: "scene_hf_ddsy",
          icon_img: road_icon,
          top: "54%",
          left: "43%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 57,
          text: "Đường Dạo Shinrin Yoku",
          frame: "scene_hf_ddsy",
          icon_img: road_icon,
          top: "62%",
          left: "40%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 57,
          text: "Đường Dạo Shinrin Yoku",
          frame: "scene_hf_ddsy",
          icon_img: road_icon,
          top: "69%",
          left: "55%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 57,
          text: "Đường Dạo Shinrin Yoku",
          frame: "scene_hf_ddsy",
          icon_img: road_icon,
          top: "54%",
          left: "51%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 59,
          text: "Cắm Trại Bên Rừng",
          // iconRight: true,
          frame: "scene_hf_ctbr",
          icon_img: CTBR_icon,
          top: "53%",
          left: "34%",
          topText: "58%",
          leftText: "69%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        // {
        //   id: 66,
        //   flaghotspot: true,
        //   visible: false,
        //   // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
        //   text: "Khu Biệt Thự Onsen",
        //   top: "49%",
        //   left: "74%",
        //   topMobile: "38%",
        //   leftMobile: "29%",
        // },
        {
          id: 67,
          flaghotspot: true,
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
          text: "Đại Lộ Boulevard",
          top: "60%",
          left: "30%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 68,
          flaghotspot: true,
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
          text: "Playing Forest",
          top: "55%",
          left: "58%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 69,
          flaghotspot: true,
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
          text: "Healing Forest",
          top: "42%",
          left: "27%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 70,
          flaghotspot: true,
          // img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/oc.jpg",
          text: "Farming Forest",
          top: "60%",
          left: "43%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 71,
          text: "quảng trường ven sông",
          img: "https://360.goku.agency/goku-data/ecovillage-saigon-river/tien_ich/qtvs.jpg",
          icon_img: QT_icon,
          top: "25%",
          left: "60%",
          topText: "76%",
          leftText: "75%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        // Công Viên Riverwalk
        {
          id: 74,
          text: "vườn sỏi hành thiền",
          icon_img: VDCN_icon,
          frame: "scene_cvrw02_d",
          top: "29.5%",
          left: "52%",
          topText: "45%",
          leftText: "52%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 75,
          text: "vườn đá chánh niệm",
          icon_img: VDCN_icon,
          frame: "scene_cvrw02",
          top: "26%",
          left: "51%",
          topText: "48%",
          leftText: "51%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 72,
          text: "Vườn Âm Thanh",
          icon_img: VAT_icon,
          frame: "scene_cvrw01_d",
          // iconRight: true,
          top: "26.2%",
          left: "48%",
          topText: "42%",
          leftText: "42.8%",
          topMobile: "24%",
          leftMobile: "24%",
        },
        {
          id: 73,
          text: "vườn yoga dưỡng sinh",
          icon_img: VYDS_icon,
          frame: "scene_cvrw01",
          // iconRight: true,
          top: "29.5%",
          left: "49%",
          topText: "46%",
          leftText: "41.1%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 76,
          text: "Sân Bóng Đa Năng",
          icon_img: SBR_icon,
          frame: "scene_sc_sportfield",
          // iconRight: true,
          top: "64%",
          left: "63%",
          topText: "46%",
          leftText: "41.1%",
          topMobile: "38%",
          leftMobile: "29%",
        },
        {
          id: 77,
          text: "Sân Tennis",
          icon_img: tennis_icon,
          frame: "scene_sc_tennis",
          // iconRight: true,
          top: "67.5%",
          left: "66%",
          topText: "46%",
          leftText: "41.1%",
          topMobile: "38%",
          leftMobile: "29%",
        },

        // Tiện ích id: 77
      ],
      utilsSubmenu: [
        {
          id: 1,
          text: "Bến Du Thuyền",
          scene: "scene_CVVS03",
          childmenu: [
            {
              id: 1,
              text: "Bến Du Thuyền",
              scene: "scene_CVVS03",
            },
            {
              id: 2,
              text: "Cafe Ngoài Trời",
              scene: "scene_bdt_cf",
            },
          ],
        },
        {
          id: 2,
          text: "Công Viên Six Senses",
          scene: "scene_cvvs01_d",
          childmenu: [
            {
              id: 3,
              text: "Đường đạp xe xuyên rừng",
              scene: "scene_cvvs01_d",
            },
            // {
            //   id: 4,
            //   text: "Nhà hàng thực dưỡng ven sông",
            //   scene: "scene_cvvs02",

            // },
            {
              id: 5,
              text: "Outdoor Gym Center",
              scene: "scene_cvvs02_d",
            },
            {
              id: 6,
              text: "Đường chạy bộ 5 giác quan",
              scene: "scene_cvvs01",
            },
          ],
        },
        {
          id: 3,
          text: "Công Viên Riverwalk",
          childmenu: [
            {
              id: 72,
              text: "vườn âm thanh",
              scene: "scene_cvrw01_d",
            },
            {
              id: 73,
              text: "vườn yoga dưỡng sinh",
              scene: "scene_cvrw01",
            },
            {
              id: 74,
              text: "vườn sỏi hành thiền",
              scene: "scene_cvrw02_d",
            },
            {
              id: 75,
              text: "vườn đá chánh niệm",
              scene: "scene_cvrw02",
            },
          ],
        },
        {
          id: 4,
          text: "Quảng Trường All Blue",
          scene: "scene_qttt_daiphunnuoc",
          childmenu: [
            {
              id: 7,
              text: "Quảng Trường Xanh",
              scene: "scene_qtx_cinema",
              childmenu2: [
                {
                  id: 7001,
                  text: "Thềm Vọng Cảnh",
                  scene: "scene_qtx_themvongcanh",
                },
                {
                  id: 7002,
                  text: "Rạp Chiếu Phim Ngoài Trời",
                  scene: "scene_qtx_cinema",
                },
                {
                  id: 7003,
                  text: "Sân Cỏ Đa Năng",
                  scene: "scene_qtx_sancodanang",
                },
              ],
            },
            {
              id: 8,
              text: "Quảng Trường Nhiệt Đới",
              scene: "scene_qtnd_vdn",
              childmenu2: [
                {
                  id: 8001,
                  text: "Vườn Dược Liệu",
                  scene: "scene_cvtt01_d",
                },
                {
                  id: 8002,
                  text: "Đường Chạy Ion Âm",
                  scene: "scene_cvtt01",
                },
                {
                  id: 8003,
                  text: "Vườn Dã Ngoại",
                  scene: "scene_qtnd_vdn",
                },
                {
                  id: 8004,
                  text: "Công Viên Ôm Cây",
                  scene: "scene_cvtt03_d",
                },
                {
                  id: 8005,
                  text: "Không Gian Đạo",
                  scene: "scene_cvtt02",
                },
                {
                  id: 8006,
                  text: "Bếp Rừng Organic",
                  scene: "scene_cvtt03",
                },
              ],
            },
            {
              id: 9,
              text: "Quảng Trường Trẻ Thơ",
              scene: "scene_qttt_vuontuoitho",
              childmenu2: [
                {
                  id: 9001,
                  text: "Vườn Tuổi Thơ",
                  scene: "scene_qttt_vuontuoitho",
                },
                {
                  id: 9002,
                  text: "Sân Trượt Patin",
                  scene: "scene_qttt_santruotpatin",
                },
                {
                  id: 9003,
                  text: "Vườn BBQ",
                  scene: "scene_qttt_vuonbbq",
                },
              ],
            },
            {
              id: 10,
              text: "Quảng Trường Trung Tâm",
              scene: "scene_CVVS03",
              childmenu2: [
                {
                  id: 10001,
                  text: "Đài Phun Nước",
                  scene: "scene_qttt_daiphunnuoc",
                },
              ],
            },
          ],
        },
        {
          id: 5,
          text: "Resort Clubhouse",
          scene: "scene_rsch01",
        },
        {
          id: 6,
          text: "Onsen Clubhouse",
          scene: "scene_rsch01",
          childmenu: [
            {
              id: 11,
              text: "Vườn Nhật",
              scene: "scene_osch_ngoaithat",
            },
            {
              id: 12,
              text: "Hồ khoáng Onsen",
              scene: "scene_osch_onsen",
            },
            {
              id: 13,
              text: "Vườn Thưởng Trà",
              scene: "scene_osch_massage",
            },
            {
              id: 14,
              text: "Sauna",
              scene: "scene_osch_sauna",
            },
            {
              id: 15,
              text: "Spa & Massage",
              scene: "scene_osch_spa",
            },
            {
              id: 16,
              text: "Nhà hàng",
              scene: "scene_osch_restaurant",
            },
          ],
        },
        {
          id: 7,
          text: "Sport Clubhouse",
          childmenu: [
            // {
            //   id: 17,
            //   text: "Sân Chơi Thể Thao",
            //   scene: "scene_sc_sportfield",
            // },
            {
              id: 76,
              text: "Sân Bóng Đa Năng",
              scene: "scene_sc_sportfield",
            },
            {
              id: 77,
              text: "Sân Tennis",
              scene: "scene_sc_tennis",
            },
            {
              id: 18,
              text: "Hồ Bơi",
              scene: "scene_sc_pool",
            },
          ],
        },
        {
          id: 8,
          text: "Playing Forest",
          childmenu: [
            {
              id: 49,
              text: "Rừng Vận Động",
              scene: "scene_pf_kln",
              childmenu2: [
                {
                  id: 49001,
                  text: "Khu Leo Núi",
                  scene: "scene_pf_kln",
                },
                {
                  id: 49002,
                  text: "Zipline",
                  scene: "scene_pf_zipline",
                },
                {
                  id: 49003,
                  text: "Sân Chơi Cát",
                  scene: "scene_pf_rvd",
                },
              ],
            },
            {
              id: 53,
              text: "Vườn BBQ",
              scene: "scene_pf_bbq",
            },
            {
              id: 54,
              text: "Rạp Chiếu Phim Ngoài Trời",
              scene: "scene_pf_cinema",
            },
            {
              id: 55,
              text: "Outdoor Gym",
              scene: "scene_pf_og",
            },
            {
              id: 56,
              text: "Vườn Kết Nối",
              scene: "scene_pf_vkn",
            },
          ],
        },
        {
          id: 9,
          text: "Healing Forest",
          childmenu: [
            {
              id: 57,
              text: "Đường Dạo Shinrin Yoku",
              scene: "scene_hf_ddsy",
            },
            {
              id: 58,
              text: "Vườn Đọc Sách",
              scene: "scene_hf_vds",
            },
            {
              id: 61,
              text: "Rừng Năng Lượng",
              scene: "scene_hf_rnl",
            },
            {
              id: 60,
              text: "Tắm Rừng",
              scene: "scene_hf_tr",
            },
            {
              id: 59,
              text: "Cắm Trại Bên Rừng",
              scene: "scene_hf_ctbr",
            },
          ],
        },
        {
          id: 10,
          text: "Farming Forest",
          childmenu: [
            {
              id: 62,
              text: "Vườn Trái Cây",
              scene: "scene_ff_vtc",
            },
            {
              id: 64,
              text: "Vườn Thảo Mộc",
              scene: "scene_ff_vtm",
            },
            {
              id: 65,
              text: "Vườn BBQ",
              scene: "scene_ff_vbbq",
            },
            {
              id: 63,
              text: "Vườn Rau Củ",
              scene: "scene_ff_vrc",
            },
          ],
        },
      ],
    },
  ],
  utilsActiveSubmenuNumberList: [],
};

const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    setUtilsActiveNumber: (state, action) => {
      state.utilsActiveNumber = action.payload;
    },
    setUtilsActiveSubmenuNumber: (state, action) => {
      state.utilsActiveSubmenuNumber = action.payload;
    },
    setUtilsActiveChildmenuNumber: (state, action) => {
      state.utilsActiveChildmenuNumber = action.payload;
    },
    setUtilsActiveSubmenuNumberList: (state, action) => {
      state.utilsActiveSubmenuNumberList = action.payload;
    },
  },
});

export default utilitiesSlice.reducer;
export const {
  setUtilsActiveNumber,
  setUtilsActiveSubmenuNumber,
  setUtilsActiveChildmenuNumber,
  setUtilsActiveSubmenuNumberList,
} = utilitiesSlice.actions;
